import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { StyledCheckBox } from '../CheckoutPage/components/Common'
import Price from '../../components/Price'
import RCIcon from '../../components/UI/RCIcon'
import { AfaCartProduct } from '../../model/afa'
import {
  useAdjustQuantitiesMutation,
  useUpdateAfaCartProductsMutation,
} from '../../services/afaCart'
import { breakpoints, duration, palette, pxToRem } from '../../style/theme'
import CartSize from './CartSize'
import { useDispatch, useSelector } from 'react-redux'
import { afaCartProductDetailsSelector, afaCartSelectors } from '../../store/afaCart/selectors'
import { useGetCartAvailabilityQuery } from '../../services/afaProduct'
import { getFluidSizeWithFullFormula as gF } from '../../style/theme'
import { Button } from './AfaCartProducts'
import {
  calculateVirtualRowHeightAfaCart,
  columnWidth,
  columnWidth4k,
  paddingTopBottom,
  rowGap,
  rowGap4k,
  sizesRow,
  sizesRow4k,
} from '../../helpers/genericHelper'
import { viewportSelector } from '../../store/viewport/selectors'
import afaCartAdjustActions from '../../store/afaCartAdjust/actions'
import {
  getCartRowAvailableQuantity,
  getNextAvailability,
  getUpcAvailabilityByDelivery,
  sortAfaSizes,
} from '../../libs/afa'
import { convertStringToFloat } from '../../libs/numbers'
import { convertDdMmYyyyToDate } from '../../libs/time'
import { format } from 'date-fns'
import { useGetAfaCartProducts } from '../../hooks/useGetAfaCartProducts'
import { useHistory } from 'react-router-dom'
import afaActions from '../../store/afa/actions'
import { useGetExpiredCartProducts } from '../../hooks/useGetExpiredCartProducts'
import classnames from 'classnames'
import useAfaGetOutOfAssortmentCartProducts from '../../hooks/useAfaGetOutOfAssortmentCartProducts'
import { useGetBrandsQuery } from '../../services/afa'
import { getProductImageUrl } from '../../libs/productImages'
import { cartAdjustAllKeysSelector } from '../../store/afa/selectors'
import { afaCartNotificationsSelectors } from '../../store/afaCartNotifications/selectors'
import { AfaCartAdjustType } from '../../store/afaCartAdjust/slice'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ProductSummaryWrapper = styled.div`
  display: flex;
`

const ProductSizesWrapper = styled.div<{
  open: boolean
  rowHeightOpen: number
}>`
  background-color: ${palette.white};
  border: solid 2px ${palette.littleGrey};
  transition: max-height ${duration.short}ms;
  max-height: ${({ open, rowHeightOpen }) => (open ? `${rowHeightOpen}px` : 0)};
  padding: 0 ${gF('px', 32, 32, 1366, 3840)};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;

  @media screen and (max-width: ${breakpoints.L}) {
    border: solid 1px ${palette.littleGrey};
  }

  ::before,
  ::after {
    content: '';
    height: ${gF('px', 24, 40, 1366, 3840)};
  }
`

const TopProductSizesWrapper = styled.div<{
  numberOfRows: number
  rowGap: number
  rowGap4k: number
  sizesRow: number
  sizesRow4k: number
}>`
  display: grid;
  grid-row-gap: ${props => gF('px', props.rowGap, props.rowGap4k, 1366, 3840)};
  grid-column-gap: ${gF('px', 29, 40, 1366, 3840)};
  grid-template-columns: 8rem 1fr;
  grid-template-rows: repeat(${props => props.numberOfRows}, ${pxToRem(sizesRow4k)}rem);

  @media screen and (max-width: ${breakpoints.L}) {
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(${props => props.numberOfRows}, ${pxToRem(sizesRow)}rem);
  }
`
const CartSizeTitleWrapper = styled.div``

const CartSizesWrapper = styled.div<{
  numberOfRows: number
  rowGap: number
  rowGap4k: number
  columnWidth: number
  columnWidth4k: number
  sizesRow: number
  sizesRow4k: number
}>`
  display: grid;
  grid-column-gap: ${gF('px', 38, 49, 1366, 3840)};
  grid-row-gap: ${props => gF('px', props.rowGap, props.rowGap4k, 1366, 3840)};
  grid-template-columns: repeat(7, minmax(auto, 176px));
  grid-template-rows: repeat(${props => props.numberOfRows}, ${pxToRem(sizesRow4k)}rem);

  @media screen and (max-width: ${breakpoints.L}) {
    grid-template-columns: repeat(6, minmax(auto, 120px));
    grid-template-rows: repeat(${props => props.numberOfRows}, ${pxToRem(sizesRow)}rem);
  }
`

const CartSizeTitle = styled.div<{ rowGap: number; rowGap4k: number }>`
  height: 100%;
  margin-bottom: ${props => gF('px', props.rowGap, props.rowGap4k, 1366, 3840)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  font-weight: bold;
  color: ${palette.tangaroa};
  text-transform: capitalize;
  font-size: ${gF('px', 16, 24, 1366, 3840)};
`

const BottomProductSizesWrapper = styled.div<{ paddingTopBottom: number }>`
  display: flex;
  justify-content: space-between;
  padding-top: ${props => props.paddingTopBottom / 1.5}px;
  align-items: center;

  & span {
    text-decoration: underline;
    color: ${palette.tangaroa};
    font-size: ${gF('px', 12, 24, 1366, 3840)};
    cursor: pointer;
  }
`
const AdjustAvailability = styled.span<{
  visible: boolean
}>`
  text-decoration: underline;
  color: ${palette.tangaroa};
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  cursor: pointer;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const MiddleLine = styled.div`
  border-bottom: solid ${pxToRem(1.4)}rem transparent;
`

const ProductAndSize = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ProductWrapper = styled.div`
  display: flex;
  border: solid 2px ${palette.littleGrey};
  background-color: ${palette.white};

  @media screen and (max-width: ${breakpoints.L}) {
    border: solid 1px ${palette.littleGrey};
  }
`
const inputSize = 20
const CheckboxWrapper = styled.div<{ rowHeightClose: number }>`
  margin-right: ${pxToRem(24)}rem;
  position: relative;
  top: ${props => props.rowHeightClose / 2 - inputSize / 2}px;

  input {
    background-color: ${palette.white};
    width: ${gF('px', 18, 30, 1366, 3840)};
    height: ${gF('px', 18, 30, 1366, 3840)};
  }
`

const ProductImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: ${palette.white};
  width: ${gF('px', 174, 249, 1366, 3840)};
  height: ${gF('px', 174, 249, 1366, 3840)};
  box-sizing: content-box;
`

const ProductImage = styled.img`
  position: relative;
  cursor: pointer;
  max-height: 100%;
  object-fit: contain;

  &::before {
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    background-color: ${palette.wildSand};
    position: absolute;
  }
`

const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${pxToRem(24)}rem ${pxToRem(20)}rem;
`

const ProductDetailsLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)}rem;
`

const ProductName = styled.div`
  color: ${palette.cloudBurst};
  font-weight: bold;
  font-size: ${gF('px', 16, 24, 1366, 3840)};
  text-transform: uppercase;
  cursor: pointer;
`

const ProductDetails = styled.div`
  display: flex;
  gap: ${pxToRem(24)}rem;

  span {
    font-size: ${gF('px', 12, 18, 1366, 3840)};
    font-family: GilmerRegular;
    color: ${palette.cloudBurst};
  }
`

const SinglePrice = styled(Price)`
  color: ${palette.tangaroa};

  & .product-prices__whsPrice {
    color: ${palette.tangaroa};
    display: inline;
    font-size: ${gF('px', 12, 18, 1366, 3840)};
    font-family: GilmerBold;
  }

  & .product-prices__srPrice {
    &::before {
      content: ' - ';
      font-size: ${gF('px', 12, 18, 1366, 3840)};
      white-space: pre;
    }

    font-family: GilmerRegular;
    color: ${palette.doveGray};
    display: inline;
    font-size: ${gF('px', 12, 18, 1366, 3840)};
  }
`

const ProductColorName = styled.div`
  font-family: 'GilmerRegular', sans-serif;
  letter-spacing: 0.19px;
  color: ${palette.cloudBurst};
  font-size: ${gF('px', 12, 18, 1366, 3840)};
  margin: ${pxToRem(4)}rem 0;
  text-transform: capitalize;

  span:last-of-type {
    font-family: GilmerBold;
  }
`

const ProductButton = styled.button`
  line-height: 0;
  font-size: ${gF('px', 14, 24, 1366, 3840)};
  color: ${palette.tangaroa};
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${pxToRem(8)}rem;
  text-transform: capitalize;

  img {
    height: ${pxToRem(20)}rem;
  }

  &.remove {
    margin-top: auto;
  }
`

const ProductDetailsRight = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
`

const TotalPrice = styled(Price)`
  .product-prices__whsPrice {
    color: ${palette.tangaroa};
    font-size: ${gF('px', 16, 24, 1366, 3840)};
    font-weight: bold;
  }

  .product-prices__srPrice {
    padding-top: ${pxToRem(9)}rem;
    color: ${palette.doveGray};
    font-size: ${gF('px', 12, 16, 1366, 3840)};
  }
`

const ProductQuantity = styled.div`
  font-size: ${gF('px', 14, 18, 1366, 3840)};

  span.items {
    color: ${palette.tangaroa};

    &.expired {
      color: ${palette.darkYellow};
    }
  }

  span.available {
    margin-left: 1em;
    color: ${palette.darkYellow};
    font-family: GilmerBold, sans-serif;
  }
`
const AvailableFrom = styled.div`
  color: ${palette.tangaroa};
`

type Props = {
  checked: boolean
  onCheck: () => void
  cartProductsByMoco: AfaCartProduct[]
  toggleOpen?: () => void
  open?: boolean
  removeFromCart: () => void
}

const AfaCartProductCard: React.FC<Props> = ({
  onCheck,
  checked,
  cartProductsByMoco,
  open = false,
  toggleOpen = () => undefined,
  removeFromCart,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [adjustQuantities, adjustQuantitiesResult] = useAdjustQuantitiesMutation({
    cacheKey: 'adjustAll',
  })
  const brands = useGetBrandsQuery()

  const showNotifications = useSelector(afaCartNotificationsSelectors.showNotifications)
  const { height } = useSelector(viewportSelector)

  const upc = cartProductsByMoco[0]?.upc || ''
  const modelCode = cartProductsByMoco[0]?.modelCode || ''
  const colorCode = cartProductsByMoco[0]?.colorCode || ''
  const productName = cartProductsByMoco[0]?.modelDescription || ''
  const productImg = cartProductsByMoco[0]?.urlImage || ''
  const availabilityDate = cartProductsByMoco[0]?.availabilityDate || ''

  const product = useSelector(afaCartProductDetailsSelector(modelCode))
  const moco = product?.mocos[colorCode]
  const mocoSizes = moco?.sizes

  const { cartProducts } = useGetAfaCartProducts()
  const keysToAdjust = useSelector(cartAdjustAllKeysSelector)

  const outOfAssortmentProducts = useAfaGetOutOfAssortmentCartProducts()
  const isOutOfAssortment = outOfAssortmentProducts.some(
    outOfAssortmentProduct => outOfAssortmentProduct.upc === upc,
  )

  const [, updateCartProductsResult] = useUpdateAfaCartProductsMutation()

  const availabilityQuery = useGetCartAvailabilityQuery()
  const dropsRealtime = useSelector(afaCartSelectors.dropsRealtime)
  const { expiredDropsCartProducts } = useGetExpiredCartProducts()
  const expiredProductsKeys = expiredDropsCartProducts.map(({ key }) => key)

  const availabilityByMoco = useMemo(
    () =>
      Object.keys(moco?.sizes || {}).map(mocoUpc => {
        const cartProduct = cartProductsByMoco.find(({ upc }) => upc === mocoUpc)
        const availableQuantity =
          cartProduct && getCartRowAvailableQuantity(cartProduct, dropsRealtime || undefined)

        return { upc: mocoUpc, availableQuantity }
      }),
    [dropsRealtime, moco?.sizes, cartProductsByMoco],
  )

  const countSizeQuantityByDelivery = useCallback(
    (sizeUpc: string, sizeAvailabilityDate: string) =>
      cartProductsByMoco
        .filter(
          ({ upc, availabilityDate }) =>
            upc === sizeUpc && availabilityDate === sizeAvailabilityDate,
        )
        .reduce((sum, cartProduct) => sum + cartProduct.unconfirmedQuantity, 0),
    [cartProductsByMoco],
  )

  const totalAvailability = useMemo(() => {
    const upcsAvailability = Object.values(cartProductsByMoco).reduce((result, cartProduct) => {
      const availability = getUpcAvailabilityByDelivery({
        availabilityDate: cartProduct.availabilityDate,
        availabilityByUpc: availabilityByMoco,
        cartProducts,
        upc: cartProduct.upc,
        deliveryDate: cartProduct.deliveryDate,
      })
      if (availability !== undefined) {
        result[cartProduct.upc] = availability
      }
      return result
    }, {} as Record<string, number>)

    if (!Object.entries(upcsAvailability)?.length) {
      return undefined
    }

    const result = Object.entries(upcsAvailability).reduce((sum, [upc, availability]) => {
      const quantity = Object.values(cartProductsByMoco)
        .filter(cartProduct => cartProduct.upc === upc)
        .reduce((sum, { unconfirmedQuantity }) => unconfirmedQuantity + sum, 0)
      if (quantity > availability) {
        return sum + (availability < 0 ? 0 : availability)
      } else {
        return sum + quantity
      }
    }, 0)
    return result
  }, [availabilityByMoco, cartProducts, cartProductsByMoco])

  const mocosSizes = Object.values(mocoSizes || {}).map(({ upc, size }) => ({ upc, size }))
  const mocosSizesUpcs = mocosSizes.map(({ upc }) => upc)

  const sizesToRenderMap = cartProductsByMoco.reduce((result, cartProduct) => {
    if (cartProduct.upc in result || mocosSizesUpcs.includes(cartProduct.upc)) {
      return result
    }
    const size = (cartProduct.skuCode || '')
      .replace(cartProduct.modelCode, '')
      .replace(cartProduct.colorCode, '')
      .trim()
    result[cartProduct.upc] = size || ''

    return result
  }, {} as Record<string, string>)

  const sizesToRender = Object.entries(sizesToRenderMap)
    .map(([upc, size]) => ({ upc, size }))
    .concat(mocosSizes)

  const rowHeightClose = calculateVirtualRowHeightAfaCart(height, sizesToRender.length).close
  const rowHeightOpen = calculateVirtualRowHeightAfaCart(height, sizesToRender.length).open
  const numberOfRows = calculateVirtualRowHeightAfaCart(height, sizesToRender.length)
    .rowsToVisualize

  const totalQuantity = cartProductsByMoco.reduce((total, cartProduct) => {
    total += cartProduct.unconfirmedQuantity
    return total
  }, 0)

  const isExpiredProduct = cartProductsByMoco.some(({ key }) => expiredProductsKeys.includes(key))

  const availableFrom = getNextAvailability(cartProductsByMoco, availabilityQuery.data)

  const showAdjustAvailability =
    showNotifications &&
    cartProductsByMoco.some(({ upc, availabilityDate, deliveryDate }) => {
      const availability = getUpcAvailabilityByDelivery({
        cartProducts,
        upc,
        availabilityDate,
        deliveryDate,
        availabilityByUpc: availabilityByMoco,
      })
      return (
        availability !== undefined &&
        availability < countSizeQuantityByDelivery(upc, availabilityDate)
      )
    })

  const brand = brands.data?.find(({ code }) => code === cartProductsByMoco[0]?.brandCodeParent)
  const currency = brand?.currency || brands.data?.[0]?.currency || ''

  return (
    <Wrapper>
      <ProductSummaryWrapper>
        <CheckboxWrapper rowHeightClose={rowHeightClose}>
          <StyledCheckBox
            borderColor={palette.tangaroa}
            accentColor={palette.tangaroa}
            checked={checked}
            onChange={onCheck}
          />
        </CheckboxWrapper>

        <ProductAndSize>
          <ProductWrapper className="afa-cart-product-card">
            <ProductImageWrapper>
              <ProductImage
                onClick={() => history.push(`pdp/${modelCode}?tab=details&color=${colorCode}`)}
                src={getProductImageUrl({
                  path: moco?.catalogImgPath || productImg,
                  imwidth: 300,
                  scale: true,
                })}
                alt=""
              />
            </ProductImageWrapper>

            <ProductDetailsWrapper>
              <ProductDetailsLeft>
                <ProductName
                  onClick={() => history.push(`pdp/${modelCode}?tab=details&color=${colorCode}`)}
                >
                  {product?.name || productName}
                </ProductName>
                <ProductDetails>
                  <span>{modelCode}</span>
                  {!!moco?.availabilityDataList.length && (
                    <span>{moco.availabilityDataList[0].poCode}</span>
                  )}
                  {!!moco?.availabilityDataList.length && (
                    <span>
                      {t('Afa.avd')}{' '}
                      {format(new Date(moco.availabilityDataList[0].avdDate), 'dd/MM/yyyy')}
                    </span>
                  )}
                </ProductDetails>

                <SinglePrice
                  variant="horizontal"
                  whsPrice={convertStringToFloat(cartProductsByMoco[0]?.masterPrice.unitPriceWs)}
                  srPrice={convertStringToFloat(cartProductsByMoco[0]?.masterPrice.unitPriceRt)}
                  currency={currency}
                />

                <ProductColorName>
                  <span>{t('Afa.color')}: </span>
                  <span>{moco?.colorDescription || colorCode}</span>
                </ProductColorName>

                <ProductButton
                  className="remove"
                  onClick={removeFromCart}
                  disabled={updateCartProductsResult.isLoading}
                >
                  <RCIcon type="trash" />
                  <span>{t('Afa.Cart.Remove')}</span>
                </ProductButton>
              </ProductDetailsLeft>

              <ProductDetailsRight>
                <TotalPrice
                  variant="vertical"
                  whsPrice={
                    convertStringToFloat(cartProductsByMoco[0]?.masterPrice.unitPriceWs) *
                    totalQuantity
                  }
                  srPrice={
                    convertStringToFloat(cartProductsByMoco[0]?.masterPrice.unitPriceRt) *
                    totalQuantity
                  }
                  currency={currency}
                />

                <ProductQuantity>
                  <span
                    className={classnames({
                      items: true,
                      expired: showNotifications && (isExpiredProduct || isOutOfAssortment),
                    })}
                  >
                    {totalQuantity} {t('Afa.Cart.Items')}
                  </span>
                  {showNotifications &&
                    totalAvailability !== undefined &&
                    totalQuantity > totalAvailability && (
                      <span className="available">
                        {totalAvailability} {t('Afa.Cart.Available')}
                      </span>
                    )}
                </ProductQuantity>

                {showNotifications && isExpiredProduct && availableFrom && !isOutOfAssortment && (
                  <AvailableFrom>
                    {t('Afa.Cart.AvailableFrom')}:{' '}
                    {format(convertDdMmYyyyToDate(availableFrom), 'dd/MM/uuuu')}
                  </AvailableFrom>
                )}

                {!isExpiredProduct && (
                  <ProductButton onClick={toggleOpen}>
                    <span>{open ? t('Afa.Cart.hideDetails') : t('Afa.Cart.ViewDetails')}</span>
                    {open ? <RCIcon arrow type="up" /> : <RCIcon arrow type="down" />}
                  </ProductButton>
                )}
              </ProductDetailsRight>
            </ProductDetailsWrapper>
          </ProductWrapper>

          <ProductSizesWrapper open={open} rowHeightOpen={rowHeightOpen}>
            <TopProductSizesWrapper
              numberOfRows={numberOfRows}
              rowGap={rowGap}
              rowGap4k={rowGap4k}
              sizesRow={sizesRow}
              sizesRow4k={sizesRow4k}
            >
              <CartSizeTitleWrapper>
                {new Array(numberOfRows).fill(undefined).map((_, i) => (
                  <CartSizeTitle rowGap={rowGap} rowGap4k={rowGap4k} key={i}>
                    <div>{t('Afa.fC.size')}</div>
                    <MiddleLine />
                    <div>{t('Checkout.Process.Quantity')}</div>
                  </CartSizeTitle>
                ))}
              </CartSizeTitleWrapper>
              <CartSizesWrapper
                numberOfRows={numberOfRows}
                rowGap={rowGap}
                rowGap4k={rowGap4k}
                columnWidth={columnWidth}
                columnWidth4k={columnWidth4k}
                sizesRow={sizesRow}
                sizesRow4k={sizesRow4k}
              >
                {sizesToRender
                  .slice(0)
                  .sort((a, b) => sortAfaSizes(a.size, b.size))
                  .map(size => {
                    const cartProduct = cartProductsByMoco.find(
                      cartProduct =>
                        cartProduct.upc === size.upc &&
                        cartProduct.availabilityDate === availabilityDate,
                    )
                    const quantity = countSizeQuantityByDelivery(size.upc, availabilityDate)
                    const availability = cartProduct
                      ? getUpcAvailabilityByDelivery({
                          upc: cartProduct.upc,
                          availabilityDate,
                          deliveryDate: cartProduct.deliveryDate,
                          availabilityByUpc: availabilityByMoco,
                          cartProducts,
                        })
                      : undefined

                    return (
                      <CartSize
                        key={size.upc}
                        size={size}
                        availability={availability}
                        quantity={quantity}
                      />
                    )
                  })}
              </CartSizesWrapper>
            </TopProductSizesWrapper>
            <BottomProductSizesWrapper paddingTopBottom={paddingTopBottom}>
              <AdjustAvailability
                visible={showAdjustAvailability}
                onClick={() => {
                  const upcKeysByMoco = cartProductsByMoco.map(({ upc }) => upc)
                  const mocoProductsForAllDeliveries = cartProducts.filter(({ upc }) =>
                    upcKeysByMoco.includes(upc),
                  )
                  dispatch(
                    afaActions.setCartAdjustAllKeys(
                      mocoProductsForAllDeliveries.map(({ key }) => key),
                    ),
                  )
                  dispatch(afaActions.toggleCartAdjustAllModalOpen())
                  adjustQuantities({ keys: keysToAdjust, simulate: true })
                  dispatch(afaActions.setAdjustAllIsSimulate(true))
                }}
              >
                {t('Afa.Cart.AdjustAvailability')}
              </AdjustAvailability>
              <Button
                onClick={() => {
                  adjustQuantitiesResult.reset()
                  dispatch(afaCartAdjustActions.setCartProducts(cartProductsByMoco))
                  dispatch(afaCartAdjustActions.setIsOpen(AfaCartAdjustType.MANAGE_QUANTITIES))
                }}
              >
                {t('Afa.Cart.ManageQuantities')}
              </Button>
            </BottomProductSizesWrapper>
          </ProductSizesWrapper>
        </ProductAndSize>
      </ProductSummaryWrapper>
    </Wrapper>
  )
}

export default AfaCartProductCard
