import React, { useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import { breakpointsCross, getFluidSizeWithFullFormula, palette } from '../../style/theme'
import SizeRowDropDate from './SizeRowDropDate'
import SizeRowSeasonDate from './SizeRowSeasonDate'
import Loading from '../../components/Loading'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useParams } from 'react-router'
import { useAfaSelectedColor } from '../../hooks/useAfaSelectedColor'
import { useTranslation } from 'react-i18next'
import { useGetProductQuery } from '../../services/afaProduct'
import { useDispatch, useSelector } from 'react-redux'
import { afaCartProductDetailsSelector } from '../../store/afaCart/selectors'
import { isPrebook, isSeasonSale } from '../../libs/afa'
import { AfaCatalogId, DdMmYyyyDateString, Upc } from '../../model/model'
import { AfaCartProduct, DropType } from '../../model/afa'
import { useGetExpiredCartProducts } from '../../hooks/useGetExpiredCartProducts'
import SizeRowExpiredDrop from './SizeRowExpiredDrop'
import {
  useGetAfaCartQuery,
  useUpdateAfaCartProductsMutationDebounced,
} from '../../services/afaCart'
import afaCartActions from '../../store/afaCart/actions'
import { afaVerticalScrollbarCss } from '../../components/CommonComponents'
import { sortDropsByMinDeliveryDate } from '../../libs/afa'
import { activeDoorsSelector } from '../../store/customer/selectors'
import { convertStringToFloatOrNull } from '../../libs/numbers'
import { useAfaDropsHooks } from '../../hooks/useAfaDropsHooks'
import { SizeRowTitle } from './DropRowsStyles'

const Rows = styled.div<{ itemsLength: number; activeDoorsLength: number }>`
  height: 100%;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpointsCross.XL.max}) {
    ${({ itemsLength, activeDoorsLength }) =>
      itemsLength === 1 &&
      activeDoorsLength > 1 &&
      css`
        height: calc(100% - 160px);
      `}
  }
`

const ScrollWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${getFluidSizeWithFullFormula('px', 32, 88, 1366, 3840)};
  height: 100%;
  overflow-y: auto;
  padding: 1em 0;
  ${afaVerticalScrollbarCss};
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DividerLine = styled.hr`
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: ${palette.platinum};
`

const ErrorMessage = styled.div`
  text-align: center;
  padding: 3em 0;
`

type Props = {
  selectedDoorId: string
  cartIsLoadingUpdates: boolean | undefined
}

const SizeRows: React.FC<Props> = ({ selectedDoorId, cartIsLoadingUpdates }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { modelCode } = useParams<{ modelCode: string }>()

  const { selectedColor } = useAfaSelectedColor(modelCode)

  const activeDoors = useSelector(activeDoorsSelector)

  const [searchParams] = useSearchParams()

  const selectedModelCode = searchParams.get('model') || modelCode || ''
  const selectedColorCode = searchParams.get('color') || ''

  const productQuery = useGetProductQuery(selectedModelCode, {
    skip: window.location.pathname.includes('cart'),
  })

  const pdpProductDetails = productQuery.data
  const cartProductDetails = useSelector(afaCartProductDetailsSelector(selectedModelCode))
  const productDetails = cartProductDetails || pdpProductDetails

  const productDetailsColor = Object.values(productDetails?.mocos || {}).find(
    ({ colorCode }) => colorCode === selectedColorCode,
  )

  const sizes = selectedColor?.sizes || productDetailsColor?.sizes || {}

  const allSizesAreDisabledForSelectedDoor = !Object.values(sizes).some(
    size => size.doors && size.doors.includes(selectedDoorId),
  )

  const { dropsByUpc, dropsByUpcQuery } = useAfaDropsHooks.dropsOrRealtimeDropsByUpc(
    selectedColor || productDetailsColor,
  )

  const cartQuery = useGetAfaCartQuery()
  const cartItems = cartQuery?.data?.items

  type MinDeliveryDate = string
  const [seasonSales, prebooks] = useMemo(() => {
    const seasonSaleObj = Object.values(dropsByUpc || {})
      .flat()
      .filter(isSeasonSale)
      .sort(sortDropsByMinDeliveryDate)
      .reduce(
        (
          result,
          {
            upc,
            type,
            availabilityDate,
            minDeliveryDate,
            availableQuantity,
            afaCatalogId,
            masterPrice,
          },
        ) => {
          if (!result[minDeliveryDate]) {
            result[minDeliveryDate] = {
              availabilityDate,
              minDeliveryDate,
              type,
              prices: {
                wholesale: convertStringToFloatOrNull(masterPrice.unitPriceWs),
                retail: convertStringToFloatOrNull(masterPrice.unitPriceRt),
              },
              upcs: [
                {
                  availableQuantity,
                  upc,
                  afaCatalogId,
                },
              ],
            }
          } else {
            result[minDeliveryDate].upcs.push({
              availableQuantity,
              upc,
              afaCatalogId,
            })
          }
          return result
        },
        {} as Record<
          MinDeliveryDate,
          {
            type: 'SEASON'
            availabilityDate: DdMmYyyyDateString
            minDeliveryDate: DdMmYyyyDateString
            upcs: { availableQuantity: number; upc: Upc; afaCatalogId: AfaCatalogId }[]
            prices: {
              wholesale: number | null
              retail: number | null
            }
          }
        >,
      )

    const seasonSales = Object.values(seasonSaleObj)

    const prebooksObj = Object.values(dropsByUpc || {})
      .flat()
      .filter(isPrebook)
      .sort(sortDropsByMinDeliveryDate)
      .reduce(
        (
          result,
          {
            upc,
            type,
            afaCatalogId,
            availabilityDate,
            minDeliveryDate,
            availableQuantity = 0,
            masterPrice,
          },
        ) => {
          if (!result[minDeliveryDate]) {
            result[minDeliveryDate] = {
              availabilityDate,
              minDeliveryDate,
              type,
              afaCatalogId,
              prices: {
                wholesale: convertStringToFloatOrNull(masterPrice.unitPriceWs),
                retail: convertStringToFloatOrNull(masterPrice.unitPriceRt),
              },
              upcs: [
                {
                  availableQuantity,
                  upc,
                  afaCatalogId,
                },
              ],
            }
          } else {
            result[minDeliveryDate].upcs.push({
              availableQuantity,
              upc,
              afaCatalogId,
            })
          }
          return result
        },
        {} as Record<
          MinDeliveryDate,
          {
            type: DropType
            availabilityDate: DdMmYyyyDateString
            minDeliveryDate: DdMmYyyyDateString
            afaCatalogId: AfaCatalogId
            upcs: { availableQuantity: number; upc: Upc; afaCatalogId: AfaCatalogId }[]
            prices: {
              wholesale: number | null
              retail: number | null
            }
          }
        >,
      )
    const prebooks = Object.values(prebooksObj)

    return [seasonSales, prebooks]
  }, [dropsByUpc])

  const { expiredDropsCartProducts, expiredSeasonsCartProducts } = useGetExpiredCartProducts({
    productData: {
      modelCode: selectedModelCode || modelCode,
      colorCode: selectedColorCode,
    },
  })

  const expiredDrops = expiredDropsCartProducts.reduce((result, expiredCartProduct) => {
    const key = `${expiredCartProduct.deliveryDate}_${expiredCartProduct.dropType}`

    if (!result[key]) {
      result[key] = []
    }

    result[key].push(expiredCartProduct)

    return result
  }, {} as Record<string, AfaCartProduct[]>)

  const expiredSeasons = expiredSeasonsCartProducts.reduce((result, expiredCartProduct) => {
    const key = `${expiredCartProduct.deliveryDate}_${expiredCartProduct.dropType}`

    if (!result[key]) {
      result[key] = []
    }

    result[key].push(expiredCartProduct)

    return result
  }, {} as Record<string, AfaCartProduct[]>)

  const [updateAfaCartProducts] = useUpdateAfaCartProductsMutationDebounced()

  useEffect(() => {
    dispatch(afaCartActions.setIsUpdating({ value: cartIsLoadingUpdates }))
  }, [dispatch, cartIsLoadingUpdates])

  const totalRowCount =
    Object.entries(expiredDrops).length +
    Object.entries(expiredSeasons).length +
    seasonSales.length +
    prebooks.length

  if (!dropsByUpcQuery.isFetching && allSizesAreDisabledForSelectedDoor) {
    return <ErrorMessage>{t('Afa.Cart.Unavailable.noData')}</ErrorMessage>
  }

  return (
    <Rows itemsLength={totalRowCount} activeDoorsLength={activeDoors.length}>
      <ScrollWrapper>
        {dropsByUpcQuery.isFetching && <Loading />}

        {!cartIsLoadingUpdates &&
          Object.entries(expiredDrops)
            .sort()
            .map(([dropKey, expiredProducts]) => {
              const expiredProductsFilteredByDoor = expiredProducts.filter(
                product => product.doorId === selectedDoorId,
              )
              return (
                expiredProductsFilteredByDoor?.length > 0 && (
                  <SizeRowExpiredDrop
                    key={dropKey}
                    sizes={Object.values(productDetailsColor?.sizes || {})}
                    cartProducts={expiredProductsFilteredByDoor}
                  />
                )
              )
            })}

        {Object.entries(expiredSeasons)
          .sort()
          .map(([dropKey, expiredProducts]) => {
            return (
              <SizeRowSeasonDate
                key={dropKey}
                availabilityDate={expiredProducts[0].availabilityDate}
                minDeliveryDate={expiredProducts[0].deliveryDate}
                availability={expiredProducts.map(({ upc }) => ({ upc, availableQuantity: 0 }))}
                updateAfaCart={updateAfaCartProducts}
                disabled={cartIsLoadingUpdates ?? false}
                prices={{ wholesale: null, retail: null }}
              />
            )
          })}

        <RowWrapper>
          {seasonSales.length > 0 && <SizeRowTitle>{t('Afa.seasonSalesHeader')}</SizeRowTitle>}
          {seasonSales.map(seasonSale => {
            const customDeliveryItems = cartItems?.filter(
              ({ availabilityDate, deliveryDate, upc, dropType }) =>
                seasonSale.upcs.find(saleUpc => saleUpc.upc === upc) &&
                availabilityDate === seasonSale.availabilityDate &&
                seasonSale.minDeliveryDate !== deliveryDate &&
                seasonSale.type === dropType,
            )
            const customDeliveriesDates = [
              ...new Set(customDeliveryItems?.map(({ deliveryDate }) => deliveryDate)),
            ]

            return (
              <SizeRowSeasonDate
                key={seasonSale.minDeliveryDate}
                availabilityDate={seasonSale.availabilityDate}
                minDeliveryDate={seasonSale.minDeliveryDate}
                availability={seasonSale.upcs}
                updateAfaCart={updateAfaCartProducts}
                disabled={cartIsLoadingUpdates ?? false}
                customDeliveriesDates={customDeliveriesDates}
                prices={seasonSale.prices}
              />
            )
          })}
        </RowWrapper>

        {!!seasonSales.length && !!prebooks.length && <DividerLine />}

        {prebooks.map((prebook, index) => {
          const customDeliveryItems = cartItems?.filter(
            ({ availabilityDate, deliveryDate, upc, dropType }) =>
              prebook.upcs.find(prebookUpc => prebookUpc.upc === upc) &&
              availabilityDate === prebook.availabilityDate &&
              prebook.minDeliveryDate !== deliveryDate &&
              prebook.type === dropType,
          )
          const customDeliveriesDates = [
            ...new Set(customDeliveryItems?.map(({ deliveryDate }) => deliveryDate)),
          ]

          {
            return (
              <SizeRowDropDate
                availability={prebook.upcs}
                key={prebook.availabilityDate}
                dropType={'DROP'}
                afaCatalogId={prebook.afaCatalogId}
                availabilityDate={prebook.availabilityDate}
                minDeliveryDate={prebook.minDeliveryDate}
                dropNumber={(index + 1).toString()}
                updateAfaCart={updateAfaCartProducts}
                disabled={cartIsLoadingUpdates ?? false}
                customDeliveriesDates={customDeliveriesDates}
                prices={prebook.prices}
              />
            )
          }
        })}

        {!dropsByUpcQuery.isFetching &&
          !seasonSales.length &&
          !prebooks.length &&
          !Object.keys(expiredSeasons).length && (
            <ErrorMessage>{t('Afa.Cart.Unavailable.noData')}</ErrorMessage>
          )}
      </ScrollWrapper>
    </Rows>
  )
}

export default SizeRows
