import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Price from '../../components/Price'
import { getFluidSizeWithFullFormula as gF } from '../../style/theme'
import { useGetAfaCartProducts } from '../../hooks/useGetAfaCartProducts'
import { convertStringToFloat } from '../../libs/numbers'
import { breakpoints, palette, pxToRem } from '../../style/theme'
import AfaCartDeliveriesSwitcher from './AfaCartDeliveriesSwitcher'
import AfaCartNotification from './AfaCartNotification'
import AfaCartProducts from './AfaCartProducts'
import { useDispatch, useSelector } from 'react-redux'
import afaCartAdjustActions from '../../store/afaCartAdjust/actions'
import { useGetCartAvailabilityQuery } from '../../services/afaProduct'
import { useGetExpiredCartProducts } from '../../hooks/useGetExpiredCartProducts'
import useAfaGetCartUnavailableKeys from '../../hooks/useAfaGetCartUnavailableKeys'
import useAfaGetOutOfAssortmentCartProducts from '../../hooks/useAfaGetOutOfAssortmentCartProducts'
import afaCartOutOfAssortmentActions from '../../store/afaCartOutOfAssortment/actions'
import {
  afaCartNotificationsSelectors,
  currentNotificationsListSelector,
  initialNotificationCountSelector,
  initialNotificationsListSelector,
} from '../../store/afaCartNotifications/selectors'
import afaCartNotificationsSliceActions from '../../store/afaCartNotifications/actions'
import { useGetAfaCartQuery } from '../../services/afaCart'
import { useGetBrandsQuery } from '../../services/afa'
import { getAllUnavailableCartProductsFromKeys } from '../../libs/afa'
import { AfaCartAdjustType } from '../../store/afaCartAdjust/slice'

const Wrapper = styled.div`
  flex: 1;
  height: auto;
  overflow: hidden;
  background-color: ${palette.wildSand};
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.L}) {
    padding-right: 7.5rem;
  }
`

const HeaderWrapper = styled.div`
  background-color: ${palette.white};
`

const Header = styled.div<{ noNotifications: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${palette.white};
  padding: ${pxToRem(94)}rem ${pxToRem(32)}rem;
  padding-bottom: ${props => (props.noNotifications ? 0 : pxToRem(48))}rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: ${breakpoints.L}) {
    padding: ${pxToRem(20)}rem;
    padding-bottom: ${props => (props.noNotifications ? 0 : pxToRem(24))}rem;
  }
`

const NotificationsWrapper = styled.div`
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
  row-gap: ${pxToRem(32)}rem;
  position: relative;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-size: ${gF('px', 24, 64, 1366, 3840)};
  color: ${palette.tangaroa};
  font-family: GilmerBold;
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }

  @media screen and (max-width: ${breakpoints.L}) {
    text-transform: capitalize;
  }
`
const SubTitle = styled.div`
  font-size: ${gF('px', 18, 32, 1366, 3840)};
  color: ${palette.santasGray};
`

const TotalPriceBigger = styled(Price)`
  .product-prices__whsPrice {
    color: ${palette.tangaroa};
    font-size: ${gF('px', 20, 48, 1366, 3840)};
    font-family: GilmerBold;
  }
  .product-prices__srPrice {
    font-size: ${gF('px', 14, 32, 1366, 3840)};
    padding-top: ${pxToRem(2)}rem;
    color: ${palette.santasGray};
  }
`
export const OUT_OF_ASSORTMENT = 'outOfAssortment'
export const EXPIRED_PRODUCTS = 'expiredProducts'
export const UNAVAILABLE_KEYS = 'unavailableKeys'
export const SHOW_COMPLETE_CART = 'showCompleteCart'

const AfaCartContent: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const brands = useGetBrandsQuery()

  //TO DO: Renderla di nuovo disponibile alla prossima implementazione della whiteboard
  const [showCompleteCartNotification, setShowCompleteCartNotification] = useState(false)

  const dropsQuery = useGetCartAvailabilityQuery()
  const cartProductsQuery = useGetAfaCartProducts()
  const cartQuery = useGetAfaCartQuery()
  const { cartItemsLength, cartProducts } = useGetAfaCartProducts()

  const cartIsLoadingOrFetching = useMemo(() => {
    return (
      cartQuery.isLoading ||
      cartQuery.isFetching ||
      dropsQuery.isLoading ||
      dropsQuery.isFetching ||
      cartProductsQuery.isLoading ||
      cartProductsQuery.isFetching
    )
  }, [
    cartQuery.isLoading,
    cartQuery.isFetching,
    dropsQuery.isLoading,
    dropsQuery.isFetching,
    cartProductsQuery.isLoading,
    cartProductsQuery.isFetching,
  ])

  const totalWsPrice = cartProducts.reduce(
    (tot, { masterPrice, unconfirmedQuantity }) =>
      tot + convertStringToFloat(masterPrice.unitPriceWs) * unconfirmedQuantity,
    0,
  )
  const totalSrPrice = cartProducts.reduce(
    (tot, { masterPrice, unconfirmedQuantity }) =>
      tot + convertStringToFloat(masterPrice.unitPriceRt) * unconfirmedQuantity,
    0,
  )

  const { unavailableKeys } = useAfaGetCartUnavailableKeys()
  const { expiredDropsCartProducts } = useGetExpiredCartProducts()
  const outOfAssortmentCartProducts = useAfaGetOutOfAssortmentCartProducts()
  const noNotifications =
    !showCompleteCartNotification &&
    unavailableKeys.length === 0 &&
    expiredDropsCartProducts.length === 0 &&
    outOfAssortmentCartProducts.length === 0

  const showNotifications = useSelector(afaCartNotificationsSelectors.showNotifications)
  const initialNotificationCount = useSelector(initialNotificationCountSelector)
  const initialNotificationsList = useSelector(initialNotificationsListSelector)
  const currentNotificationsList = useSelector(currentNotificationsListSelector)

  const notifications = useMemo(() => {
    return {
      outOfAssortment: !!outOfAssortmentCartProducts.length,
      expiredProducts: !!expiredDropsCartProducts.length,
      unavailableKeys: !!unavailableKeys.length,
      showCompleteCart: showCompleteCartNotification,
    } as {
      [key: string]: boolean
    }
  }, [
    expiredDropsCartProducts.length,
    outOfAssortmentCartProducts.length,
    showCompleteCartNotification,
    unavailableKeys.length,
  ])

  const notificationsLength = useMemo(() => {
    return Object.values(notifications).filter(notification => notification).length
  }, [notifications])

  useEffect(() => {
    if (!cartIsLoadingOrFetching) {
      if (notificationsLength > 0 && notificationsLength >= initialNotificationCount) {
        dispatch(afaCartNotificationsSliceActions.setInitialNotificationCount(notificationsLength))
        dispatch(
          afaCartNotificationsSliceActions.setInitialNotificationsList(
            Object.entries(notifications)
              .filter(([, value]) => value === true)
              .map(([key]) => key),
          ),
        )
      }
      if (
        notificationsLength < initialNotificationCount &&
        notificationsLength === 0 &&
        currentNotificationsList.length === 0
      ) {
        dispatch(afaCartNotificationsSliceActions.setInitialNotificationCount(0))
        dispatch(afaCartNotificationsSliceActions.setInitialNotificationsList([]))
      }
    }
  }, [
    notificationsLength,
    dispatch,
    initialNotificationCount,
    currentNotificationsList,
    notifications,
    cartIsLoadingOrFetching,
  ])

  useEffect(() => {
    if (!cartIsLoadingOrFetching) {
      notificationsLength > 0
        ? dispatch(
            afaCartNotificationsSliceActions.setCurrentNotificationsList(
              Object.entries(notifications)
                .filter(([, value]) => value === true)
                .map(([key]) => key),
            ),
          )
        : dispatch(afaCartNotificationsSliceActions.setCurrentNotificationsList([]))
    }
  }, [notifications, notificationsLength, dispatch, cartIsLoadingOrFetching])

  const getCount = (key: string) => {
    return initialNotificationsList.findIndex(notificationName => notificationName === key) + 1
  }

  const getAfaCartNotification = () => {
    if (notifications.outOfAssortment) {
      return (
        <AfaCartNotification
          notificationCounter={getCount(OUT_OF_ASSORTMENT)}
          title={t('Afa.Cart.OutOfAssortment.Title')}
          body={t('Afa.Cart.OutOfAssortment.Body')}
          actions={[
            {
              label: t('Afa.Cart.OutOfAssortment.Remove'),
              onClick: () => dispatch(afaCartOutOfAssortmentActions.setIsOpen(true)),
            },
          ]}
        />
      )
    }

    const expiredUpcs = expiredDropsCartProducts.map(({ upc }) => upc)
    const expiredPayload = cartProducts.filter(prod => expiredUpcs.includes(prod.upc))

    if (notifications.expiredProducts) {
      return (
        <AfaCartNotification
          notificationCounter={getCount(EXPIRED_PRODUCTS)}
          title={t('Afa.Cart.Expired.Title')}
          body={t('Afa.Cart.Expired.Text')}
          bodyAndBtnGap={80}
          actions={[
            {
              label: t('Afa.Cart.Expired.CTA'),
              onClick: () => {
                dispatch(afaCartAdjustActions.setCartProducts(expiredPayload))
                dispatch(afaCartAdjustActions.setIsOpen(AfaCartAdjustType.EXPIRED_DROPS))
              },
            },
          ]}
        />
      )
    }

    if (notifications.unavailableKeys) {
      return (
        <AfaCartNotification
          notificationCounter={getCount(UNAVAILABLE_KEYS)}
          title={t('Afa.Cart.Unavailable.OutOfStock')}
          body={t('Afa.Cart.Unavailable.Body')}
          bodyAndBtnGap={70}
          actions={[
            {
              label: t('Afa.Cart.Unavailable.Adjust'),
              onClick: () => {
                dispatch(
                  afaCartAdjustActions.setCartProducts(
                    getAllUnavailableCartProductsFromKeys(cartProducts, unavailableKeys, true),
                  ),
                )
                dispatch(afaCartAdjustActions.setIsOpen(AfaCartAdjustType.OUT_OF_STOCK))
              },
            },
          ]}
        />
      )
    }

    if (notifications.showCompleteCart) {
      return (
        <AfaCartNotification
          notificationCounter={getCount(SHOW_COMPLETE_CART)}
          title={t('Afa.Cart.Complete.Title')}
          body={t('Afa.Cart.Complete.Body')}
          actions={[
            {
              label: t('Afa.Cart.Complete.Ignore'),
              onClick: () => setShowCompleteCartNotification(false),
            },
            { label: t('Afa.Cart.Complete.Review'), onClick: () => undefined },
          ]}
        />
      )
    }
  }

  const currency = brands.data?.[0]?.currency || ''

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header id="afa-cart-content-header" noNotifications={noNotifications}>
          <TitleWrapper>
            <Title>{t('Afa.Cart.Title')}</Title>
            <SubTitle>
              {cartItemsLength} {t('Afa.Cart.Items')}
            </SubTitle>
          </TitleWrapper>

          <TotalPriceBigger
            currency={currency}
            whsPrice={totalWsPrice}
            srPrice={totalSrPrice}
            variant="vertical"
          />
        </Header>

        {showNotifications && !noNotifications && (
          <NotificationsWrapper>{getAfaCartNotification()}</NotificationsWrapper>
        )}
        <AfaCartDeliveriesSwitcher />
      </HeaderWrapper>

      <AfaCartProducts />
    </Wrapper>
  )
}

export default AfaCartContent
