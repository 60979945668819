import { toInteger } from 'lodash'
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AutoSizer } from 'react-virtualized'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import styled from 'styled-components'

import useMediaQuery from '../../hooks/useMediaQuery'
import { addCheckForLongPress } from '../../libs/stars'
import { isIPadView, isPageMatches, isTouchDevice } from '../../libs/url'
import { initPlpFiltersCounters } from '../../store/actions'
import { brandsLoadedSelector, openedBrandsSelector } from '../../store/brands/selectors'
import { doorLoadingStatusSelector } from '../../store/customer/selectors'
import { plpFiltersCategorySelector } from '../../store/filters/selectors'
import starsActions from '../../store/stars/actions'
import {
  selectedMonthSelector,
  starsAssortmentLoadedBrandCodesSelector,
  starsModulesProductsCountSelector,
  starsModulesToShowSelector,
  starsProductsCountSelector,
  starsShowInOutSelector,
} from '../../store/stars/selectors'
import { breakpoints, getFluidFontSizeDe, palette, pxToRem, spacing } from '../../style/theme'
import { LeftArrow, RightArrow } from '../Arrow/Arrow'
import Loading from '../Loading'
import PlpSidebar from '../PlpSidebar/PlpSidebar'
import CouvetteStarsModule from './CouvetteStarsModule'
import { isKidCategoryModeEnabledSelector } from '../../store/app/selectors'
import { isSubBrandCategory } from '../../helpers/genericHelper'

export const couvetteStarsModuleItemWidth = spacing(35.6)
export const couvetteStarsModulePadding = spacing(2.25)
export const couvetteStarsModuleWidth =
  couvetteStarsModuleItemWidth + couvetteStarsModulePadding * 2

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;

  @media (max-width: ${breakpoints.M}) {
    flex-direction: column;
  }
`

const CouvetteEmpty = styled.div`
  color: ${palette.mineShaft};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${getFluidFontSizeDe(15, 25)};
`

const Couvette = styled(VariableSizeList)``

const CouvetteWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: ${pxToRem(spacing(5))}rem ${pxToRem(spacing(2))}rem;
  background-color: ${palette.wildSand};
`

const CouvetteStars: React.FC = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const selectedMonth = useSelector(selectedMonthSelector)
  const modulesProductsCount = useSelector(starsModulesProductsCountSelector)
  const productsCount = useSelector(starsProductsCountSelector)
  const modules = useSelector(starsModulesToShowSelector)
  const showInOut = useSelector(starsShowInOutSelector)
  const starsAssortmentLoaded = useSelector(starsAssortmentLoadedBrandCodesSelector)
  const doorsLoadingStatus = useSelector(doorLoadingStatusSelector)
  const brandsLoaded = useSelector(brandsLoadedSelector)
  const openedBrands = useSelector(openedBrandsSelector)
  const filtersActiveCategory = useSelector(plpFiltersCategorySelector)
  const isJuniorMode = useSelector(isKidCategoryModeEnabledSelector)

  const isMaxLarge = useMediaQuery('max', 'L')

  const [isScrolledLeft, setIsScrolledLeft] = useState(false)
  const [isScrolledRight, setIsScrolledRight] = useState(false)
  const [inOut, setInOut] = useState(showInOut)

  const getSize = (index: number) => {
    const module = modules[index]
    const moduleProductsCount = modulesProductsCount?.[module.id]
    return !moduleProductsCount
      ? 0
      : couvetteStarsModulePadding +
          couvetteStarsModuleWidth *
            (2 +
              toInteger(moduleProductsCount.productsIn > 0) +
              toInteger(moduleProductsCount.productsOut > 0)) *
            (isMaxLarge ? 1 : 2)
  }

  const innerRef = useRef<HTMLElement>()
  const outerRef = useRef<HTMLElement>()
  const outerRefMounted = useRef(false)
  const leftArrowRef = useRef(null)
  const rightArrowRef = useRef(null)
  const intervalReference = useRef(null)
  const listRef = createRef<VariableSizeList>()

  useEffect(() => {
    modules && listRef.current?.resetAfterIndex(0)
  }, [modules, listRef])

  const filteredOpenedBrands = useMemo(() => {
    return openedBrands
      .flatMap(({ subBrands }) => subBrands)
      .filter(subBrand => isSubBrandCategory(subBrand, filtersActiveCategory || '', isJuniorMode))
  }, [openedBrands, filtersActiveCategory, isJuniorMode])

  useEffect(() => {
    // load products if there are brands and if is not the search result page, where products are loaded by the searchCouvettes action
    if (filteredOpenedBrands.length > 0 && !isPageMatches('products')) {
      dispatch(starsActions.loadStarsAssortment(filteredOpenedBrands.map(({ code }) => code)))
    }
  }, [dispatch, filteredOpenedBrands, isJuniorMode, filtersActiveCategory])

  useEffect(() => {
    if (productsCount > 0) {
      dispatch(initPlpFiltersCounters())
    }
  }, [dispatch, productsCount, selectedMonth])

  useEffect(() => {
    if (!outerRefMounted.current && outerRef.current) {
      outerRef.current.tabIndex = -1
      outerRef.current.onmousedown = e => {
        e.stopImmediatePropagation()
        e.preventDefault()
      }
      outerRef.current.focus()
      outerRefMounted.current = true
    }
  }, [outerRef])

  const handleScroll = useCallback(() => {
    if (outerRef.current) {
      const scrolledLeft = outerRef.current.scrollLeft > 0
      scrolledLeft !== isScrolledLeft && setIsScrolledLeft(scrolledLeft)

      const scrolledRight =
        outerRef.current.scrollLeft + outerRef.current.clientWidth !== outerRef.current.scrollWidth
      scrolledRight !== isScrolledRight && setIsScrolledRight(scrolledRight)
    }
  }, [isScrolledLeft, isScrolledRight])

  useEffect(() => {
    showInOut !== inOut && setInOut(showInOut)
    handleScroll()
  }, [handleScroll, inOut, setInOut, showInOut])

  const CouvetteModule = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const module = modules[index]
      return module ? <CouvetteStarsModule style={style} module={module} /> : <></>
    },
    [modules],
  )

  if (!modules) {
    return <Loading />
  }

  const moveRight = () => {
    addCheckForLongPress('right', rightArrowRef.current, outerRef, intervalReference, () => {
      if (outerRef.current) {
        outerRef.current.scrollLeft += couvetteStarsModuleWidth
      }
    })
  }

  const moveLeft = () => {
    addCheckForLongPress('left', leftArrowRef.current, outerRef, intervalReference, () => {
      if (outerRef.current) {
        outerRef.current.scrollLeft -= couvetteStarsModuleWidth
      }
    })
  }

  const isTouch = isTouchDevice()
  const overscanCount = isIPadView() ? 5 : 7

  return (
    <Wrapper>
      <PlpSidebar />
      {productsCount === 0 &&
        filteredOpenedBrands.some(brand => starsAssortmentLoaded.includes(brand.code)) &&
        doorsLoadingStatus !== 'pending' &&
        brandsLoaded &&
        filtersActiveCategory && (
          <CouvetteWrapper>
            <CouvetteEmpty>{t('Plp.couvetteEmpty')}</CouvetteEmpty>
          </CouvetteWrapper>
        )}
      {productsCount === 0 &&
        (filteredOpenedBrands.every(brand => !starsAssortmentLoaded.includes(brand.code)) ||
          doorsLoadingStatus === 'pending' ||
          !brandsLoaded ||
          !filtersActiveCategory) && <Loading />}
      {productsCount > 0 && (
        <CouvetteWrapper>
          {!isTouch && isScrolledLeft && <LeftArrow ref={leftArrowRef} onMouseDown={moveLeft} />}
          {!isTouch && isScrolledRight && (
            <RightArrow ref={rightArrowRef} onMouseDown={moveRight} />
          )}
          <AutoSizer>
            {({ width, height }) => {
              return (
                <Couvette
                  ref={listRef}
                  height={height}
                  itemCount={modules.length}
                  itemSize={getSize}
                  layout="horizontal"
                  width={width}
                  innerRef={innerRef}
                  outerRef={outerRef}
                  overscanCount={overscanCount}
                  onScroll={handleScroll}
                >
                  {CouvetteModule}
                </Couvette>
              )
            }}
          </AutoSizer>
        </CouvetteWrapper>
      )}
    </Wrapper>
  )
}

export default CouvetteStars
